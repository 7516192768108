<template>
	<div class="settings content-padding">
		<div class="settings__body">
			<router-view
				name="mb_settings"
				:labels="labels"
				@bundleBasicChanged="settingsUpdated"
				:loading="loading"
				:local-data="localMBData"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

export default {
	name: 'Settings',
	data() {
		return {
			loopKey: Date.now(),
			loading: true
		}
	},
	computed: {
		...mapState('monetizationBundle', ['localMBData']),
		countChanges() {
			return this.$store.getters['monetizationBundle/countChanges'];
		}
	},
	methods: {
		...mapActions('notification', {
			addNotification: 'addNotification'
		}),
		
		...mapActions('monetizationBundle', ['update', 'getSingle']),

		...mapMutations('monetizationBundle', ['updateLocalMBDataString']),

		...mapGetters('helper', ['labels']),

		getSingleMBData() {
			this.loading = true;
			this.getSingle( { id: this.$route.params.id } )
			.then( res => {
				if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}
				this.loading = false;
			})
			.catch( err => console.log( err ) );
		},

		saveMBData() {
			this.loading = true;
			let data = {
				id: this.$route.params.id,
			};

			const keysToSave = [
				'title',
				'description',
				'promises',
				'targetAudience',
				'pricing',
				'groups',
				'status',
				'content',
				'banner',
				'overview',
				'checkout',
				'pricing',
				'authors'
			];

			// Temporary disabled due the issues with reactivity and seing changes in object
			// this.countChanges.changedKeys.forEach( key => {
			keysToSave.forEach( key => {
				data[key] = this.localMBData[key];
			} )
			
			this.update( this.$prepareForm( data ) )
			.then( res => {
				if (res.success) {
					this.addNotification( { variant: 'success', msg: ['saved'], labels: this.labels })
					this.getSingleMBData();
				} else if (res.error) {
					this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels })
				}
				this.loading = false;
			})
			.catch( err => console.log( err ) );
		},

		settingsUpdated(key, value) {
			this.updateLocalMBDataString( { key: key, value: value } );
		}
	},
	mounted() {
		this.getSingleMBData();
	},
	beforeRouteLeave (to, from, next) {
		if( !this.countChanges.saveBtnDisabled ){
			const answer = window.confirm( 'Do you really want to leave? you have unsaved changes!' );
			if (answer) {
				// TODO
				next()
			} else {
				next(false);
			}
		} else {
			next();
		}
	},
}
</script>

<style lang='scss' scoped>

.settings {

	&__header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.page-title {
			font-family: "Oswald", sans-serif;
			text-transform: uppercase;
			margin-right: 15px;
			color: $dark_text;
			font-weight: 400;
			font-size: 1rem;
		}

		> div {
			margin-bottom: 15px;
		}
	}
}

</style>